:root {
  --mainCover: url(images/homeCover.jpg);
  --skillCover: url(images/skillsCover.jpg);
}

.body {
  background-color: black;
  height: 100%;
  width: 100%;
}

.navbar-brand img {
  width: 45%;
}
.mainBackground {
  background-image: var(--mainCover);
  min-height: 95vh;
  min-width: 90vw;
  background-size: cover;
}

.navbar {
  font-family: "Raleway", sans-serif;
  background-color: white;
}

.main h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 3rem;
  font-weight: 700;
  color: #5d5c61;
  margin-top: 10%;
  text-transform: uppercase;
}

.main h4 {
  font-family: "Raleway", sans-serif;
  width: 50%;
  min-width: 50%;
  text-align: center;
  color: #666;
}
.coverText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
  min-height: 80vh;
  margin-left: 10%;
}

#overview {
  min-height: 90vh;
}

.heading {
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #5d5c61;
  margin-top: 5%;
  margin-bottom: 2%;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-weight: 700;
}

#overview .content {
  min-height: 40vh;
  margin-bottom: 5%;
}

.about {
  text-align: center;
}

/* .about #gradIcon {
  width: 30%;
} */

#overview .moreAbout {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
}

.moreAbout div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: baseline;
}

.moreAbout h6 {
  margin-right: 8px;
  text-align: left;
}

.content div {
  text-align: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-top: 5vh;
}

#overview p {
  text-align: left;
  color: #5d5c61;
  font-family: "Raleway", sans-serif;
}

#overview h3 {
  text-align: center;
  color: #5d5c61;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 5%;
}

#overview h3 {
  text-align: center;
  color: #5d5c61;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 5%;
}

/********* SKILLS *********/
#skillBackground {
  background-image: var(--skillCover);
  min-height: 95vh;
  min-width: 90vw;
  background-size: cover;
  padding-top: 5px;
  margin-top: 3%;
}

.skill {
  background: #ffffff;
  box-shadow: 1px 4px 10px 1px rgba(144, 144, 144, 0.27);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  margin: 20px;
  min-height: 25vh;
  text-align: center;
  max-width: 30%;
  width: 30%;
}
.skill i {
  font-size: 3rem;
  margin-bottom: 10%;
  margin-top: 5%;
}

.skill h4 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.3rem;
  color: #292929;
}

.skill p {
  color: #5d5c61;
  width: 90%;
  text-align: center;
  font-family: "Raleway", sans-serif;
}
.skillContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: space-around;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  min-height: auto;
  min-width: 90vw;
}

#projectContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: space-around;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  min-height: 90vh;
  min-width: 90vw;
}

#projectContainer img {
  width: 100%;
  height: 100%;
  margin-bottom: 5%;
}
#projects p {
  margin: auto;
  width: 80%;
  color: #5d5c61;
  text-align: center;
  font-family: "Raleway", sans-serif;
}
.project {
  background: #ffffff;
  box-shadow: 1px 4px 10px 1px rgba(144, 144, 144, 0.27);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 20px;
  width: 25%;
  height: 30%;
}

.project h4 {
  font-family: "Montserrat", sans-serif;
  color: #5d5c61;
}

.viewBtn {
  border: 1px solid #5d5c61;
  border-radius: 2px;
  background: #ffffff;
  font-family: "Montserrat", sans-serif;
  color: #5d5c61;
  font-weight: 400;
  height: 100%;
  text-transform: uppercase;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-content: center;
  box-shadow: 2px 3px 3px 0 rgba(153, 151, 151, 0.63);
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;

  margin: 10px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}

.readBtn {
  border-radius: 1px;
  background-color: #5d5c61;
  font-family: "Montserrat", sans-serif;
  color: white;
  text-transform: uppercase;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-content: center;
  box-shadow: 2px 3px 3px 0 rgba(153, 151, 151, 0.63);
  padding-left: 15px;
  padding-right: 15px;
  border: 2px solid #5d5c61;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 5px;
}

.projectButton {
  border-radius: 5px;
  background-color: #5d5c61;
  font-family: "Montserrat", sans-serif;
  color: white;
  text-transform: uppercase;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-content: center;
  box-shadow: 2px 3px 3px 0 rgba(153, 151, 151, 0.63);
  padding-left: 15px;
  padding-right: 15px;
  border: 2px solid #5d5c61;
  padding-top: 2px;
  padding-bottom: 2px;
  margin: 5px;
}

.project a {
  text-decoration: none;
}

.project a:hover {
  text-decoration: none;
}

.readBtn:hover {
  color: #5d5c61;
  background-color: white;
}

.viewBtn:hover {
  color: white;
  background-color: #5d5c61;
}
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: #5d5c61;
  color: #c6c6c6;
  font-family: "Montserrat", sans-serif;
  padding-top: 2%;
  margin-top: 4vh;
}

.footer h3 {
  text-transform: uppercase;
  font-size: 1.5rem;
}

#credits {
  text-transform: uppercase;
  font-size: 0.8rem;
  color: #908c8c;
}

.footerIcons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 2%;
}

.socialIcon {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.socialIcon a {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 5px;
  height: 5vh;
  flex-wrap: wrap;
}

.socialIcon img {
  height: 100%;
}

#UXcase {
  position: relative;
  text-align: center;
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #5d5c61;
}

.text h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 3rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.text h4 {
  font-family: "Raleway", sans-serif;
  font-size: 1.2rem;
}

.text h5 {
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #5d5c61;
  text-align: left;
}

.right p {
  font-family: "Raleway", sans-serif;
  text-align: left;
  font-size: 1em;
}
/* 
#uxoverview img {
  width: 100%;
} */

#uxoverview {
  min-height: 90vh;
  align-items: center;
}

.divider {
  display: block;
  margin-top: 3%;
  margin-bottom: 3%;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border: 1px solid #88cbbc;
  width: 50%;
}

.pageSection {
  text-align: center;
  font-family: "Raleway", sans-serif;
  color: #5d5c61;
  font-size: 1em;
}

.pageSection h4 {
  font-family: "Montserrat", sans-serif;
  color: #5d5c61;
  text-transform: uppercase;
  font-weight: 700;
}

.gifs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  width: 100%;
}

.gifs div {
  width: 30%;
  text-align: center;
  margin: 1%;
}

.gifs img {
  width: 100%;
}

.techIcons img {
  width: 10%;
}

#certificates {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
}
#certificates div {
  width: 30%;
  margin: 10px;
}

#not-found {
  height: 90vh;
}

.not-found-background {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url(images/404.jpg);
  height: 90vh;
  opacity: 0.5;
}

#not-found .text {
  text-align: center;
  top: 60%;
}

#not-found h2 {
  font-size: 6em;
}
#not-found h3 {
  font-size: 2rem;
  font-family: "Raleway", sans-serif;
}
/************************************ SCREEN QUERIES ************************************/
/* Small Screen */
@media only screen and (max-width: 576px) {
  /* about me */

  #overview img {
    width: 70%;
  }
  .about #gradIcon {
    width: 30%;
  }

  .heading h2 {
    font-size: 1.4rem;
  }

  /* ux content */
  .right p {
    text-align: center;
    font-size: 0.8em;
  }

  .right h4 {
    font-size: 1rem;
  }

  .right {
    align-items: center;
  }

  .text h2 {
    font-size: 1.5rem;
    width: 85vw;
  }

  .text h4 {
    font-size: 1rem;
    width: 85vw;
  }

  .pageSection {
    font-size: 0.8em;
  }

  /* skills*/
  #skillBackground {
    background-image: var(--skillCover);
    min-height: 95vh;
    min-width: 90vw;
    background-size: cover;
    padding-top: 5px;
    margin-top: 10%;
    background-position: right bottom;
  }

  .skill {
    min-height: 35vh;
    min-width: 80vw;
  }
  .skill i {
    font-size: 3em;
    margin-bottom: 10%;
  }

  .skill h4 {
    font-family: "Montserrat", sans-serif;
    font-size: 1.2rem;
  }

  .skill p {
    color: #5d5c61;
    width: 90%;
    text-align: center;
    font-family: "Raleway", sans-serif;
  }

  .main h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 1.8rem;
    font-weight: 700;
    color: #5d5c61;
    text-transform: uppercase;
    position: relative;
    top: 10vh;
    text-align: center;
    right: 25px;
    width: 90%;
    margin: 20px;
  }

  .main h4 {
    font-family: "Raleway", sans-serif;
    text-align: center;
    position: relative;
    top: 10vh;
    font-size: 1rem;
    min-width: 80%;
    right: 25px;
  }

  .coverText {
    margin-left: 0;
  }

  .mainBackground {
    background-position: 10px 10px;
    background-position: 85% 10px;

    background-size: cover;

    background-repeat: no-repeat;
  }
  .gifs div {
    width: 90%;
    text-align: center;
    margin: 1%;
  }
  .techIcons img {
    width: 10%;
  }
  .techIcons h4 {
    font-size: 1.2em;
  }

  .projectButton {
    font-size: 0.6rem;
  }
  .project {
    width: 85%;
    height: 50%;
    text-align: center;
  }
  #certificates div {
    width: 90%;
    margin: 20px;
  }
  .designImg {
    width: 90%;
    margin-bottom: 5px;
  }
  #overview .moreAbout {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  #overview p {
    text-align: center;
  }

  .moreAbout h6 {
    text-align: center;
  }
  .moreAbout div {
    align-items: center;
  }
}

/* Medium Screen */
@media only screen and (min-width: 576px) {
  #overview img {
    width: 70%;
  }
  #overview #grad {
    width: 40%;
  }
  .heading h2 {
    font-size: 1.5rem;
  }
  /* ux content */
  .right p {
    text-align: center;
    font-size: 0.8em;
  }

  .right h4 {
    font-size: 1.2rem;
  }

  .right {
    align-items: center;
  }

  .text h2 {
    font-size: 1.5rem;
    width: 85vw;
  }

  .text h4 {
    font-size: 1rem;
    width: 85vw;
  }

  /* Page Section  */
  .pageSection h4 {
    font-size: 1.2rem;
  }

  .pageSection {
    font-size: 0.8em;
  }

  #skillBackground {
    background-image: var(--skillCover);
    min-height: 95vh;
    min-width: 90vw;
    background-size: cover;
    padding-top: 5px;
    margin-top: 10%;
    background-position: right bottom;
  }
  .skill {
    min-height: 35vh;
    min-width: 60vw;
  }
  .skill i {
    font-size: 3em;
    margin-bottom: 10%;
  }

  .skill h4 {
    font-family: "Montserrat", sans-serif;
    font-size: 1.2rem;
  }

  .skill p {
    color: #5d5c61;
    width: 90%;
    text-align: center;
    font-family: "Raleway", sans-serif;
  }
  #overview #grad {
    width: 30%;
  }
  .main h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 1.8rem;
    font-weight: 700;
    color: #5d5c61;
    text-transform: uppercase;
    position: relative;
    top: 10vh;
    text-align: center;
    right: 25px;
    width: 90%;
    margin: 20px;
  }

  .main h4 {
    text-align: center;
    position: relative;
    top: 10vh;
    font-size: 1rem;
    min-width: 80%;
    right: 25px;
  }

  .coverText {
    margin-left: 0;
  }

  .mainBackground {
    background-position: 10px 10px;
    background-position: 85% 10px;

    background-size: cover;

    background-repeat: no-repeat;
  }
  .gifs div {
    width: 90%;
    text-align: center;
    margin: 1%;
  }
  .techIcons img {
    width: 10%;
  }
  #certificates div {
    width: 50%;
  }
  .designImg {
    width: 90%;
    margin-bottom: 5px;
  }

  #overview .moreAbout {
    align-items: center;
  }
  #overview p {
    text-align: center;
  }

  .moreAbout h6 {
    text-align: center;
  }
  .moreAbout div {
    align-items: center;
  }
  .project {
    width: 85%;
    height: 50%;
    max-height: 50%;
  }
}

@media only screen and (max-width: 576px) {
  .skill {
    min-height: 35vh;
    min-width: 80vw;
  }
  .skill i {
    font-size: 3em;
    margin-bottom: 10%;
  }

  .skill h4 {
    font-family: "Montserrat", sans-serif;
    font-size: 1.2rem;
  }

  .skill p {
    color: #5d5c61;
    width: 90%;
    text-align: center;
    font-family: "Raleway", sans-serif;
  }

  .main h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 1.8rem;
    font-weight: 700;
    color: #5d5c61;
    text-transform: uppercase;
    position: relative;
    top: 10vh;
    text-align: center;
    right: 25px;
    width: 90%;
    margin: 20px;
  }

  .main h4 {
    font-family: "Raleway", sans-serif;
    text-align: center;
    color: #938e94;
    position: relative;
    top: 10vh;
    font-size: 1rem;
    min-width: 80%;
    right: 25px;
  }

  .coverText {
    margin-left: 0;
  }

  .mainBackground {
    background-position: 10px 10px;
    background-position: 85% 10px;

    background-size: cover;

    background-repeat: no-repeat;
  }
}
/* Large Screen */
@media only screen and (min-width: 720px) {
  #overview img {
    width: 72%;
  }
  .skill {
    min-height: 35vh;
    min-width: 80vw;
  }
  .skill i {
    font-size: 3em;
    margin-bottom: 10%;
  }

  .skill h4 {
    font-family: "Montserrat", sans-serif;
    font-size: 1.2rem;
  }

  .skill p {
    color: #5d5c61;
    width: 90%;
    text-align: center;
    font-family: "Raleway", sans-serif;
  }
  .gifs div {
    width: 90%;
    text-align: center;
    margin: 1%;
  }
  #certificates div {
    width: 40%;
    margin: 5px;
  }
  .skill {
    min-height: 20vh;
    min-width: 40vw;
  }
  .main h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 3rem;
    font-weight: 700;
    color: #5d5c61;
    margin-top: 10%;
    text-transform: uppercase;
  }

  .main h4 {
    width: 50%;
    min-width: 50%;
    text-align: center;
  }
  .coverText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
    min-height: 80vh;
    margin-left: 10%;
  }
  .gifs div {
    width: 30%;
    text-align: center;
    margin: 1%;
  }
  .about #gradIcon {
    width: 50%;
  }
  .designImg {
    width: 45%;
    margin-bottom: 5px;
    margin-right: 2px;
  }
  #overview .moreAbout {
    align-items: center;
  }
  #overview p {
    text-align: center;
  }

  .moreAbout h6 {
    text-align: center;
  }
  .moreAbout div {
    align-items: center;
  }
  .project {
    width: 40%;
    height: 50%;
    text-align: center;
  }
}

@media only screen and (min-width: 960px) {
  #overview #grad {
    width: 70%;
  }
  .skill {
    min-height: 30vh;
    min-width: 25vw;
    width: 25vw;
    height: 30vh;
  }
  .project {
    padding: 10px;
    margin: 20px;
    width: 40%;
  }
  .viewBtn,
  .readBtn {
    font-size: 0.8rem;
  }
  .text {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #5d5c61;
  }

  .text h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 2.3rem;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .text h4 {
    font-family: "Raleway", sans-serif;
    font-size: 1.2rem;
  }

  .text h5 {
    font-family: "Raleway", sans-serif;
    font-size: 1rem;
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #5d5c61;
    text-align: left;
  }

  .right p {
    text-align: left;
    font-size: 1em;
  }

  .pageSection {
    font-size: 1em;
  }
  .pageSection p {
    text-align: left;
  }
  .gifs {
    justify-content: center;
  }
  .gifs div {
    width: 35%;
    text-align: center;
    margin: 1%;
  }

  .techIcons img {
    width: 10%;
  }
  .techIcons h4 {
    font-size: 1.2em;
  }
  #certificates div {
    width: 40%;
    margin: 8px;
  }
  #overview .moreAbout {
    align-items: center;
  }
  #overview p {
    text-align: center;
  }

  .moreAbout h6 {
    text-align: center;
  }
  .moreAbout div {
    align-items: center;
  }
}

@media only screen and (max-width: 960px) {
  #projectContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: space-around;
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    min-height: 90vh;
    min-width: 90vw;
  }

  #projectContainer img {
    width: 100%;
    height: 100%;
    margin-bottom: 5%;
  }
  #projects p {
    width: 90%;
    font-size: 0.8rem;
  }
  /* .project {
    width: 85%;
    height: 50%;
    text-align: center;
  } */

  .project h4 {
    font-size: 1.2rem;
  }

  .viewBtn,
  .readBtn {
    font-size: 0.9rem;
    padding-right: 10px;
    padding-left: 10px;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
  }

  /* .socialIcon a {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 30px;
    margin: 3px;
    height: 5vh;
    flex-wrap: wrap;
  }

  .socialIcon #codepen {
    width: 34px;
    margin: 3px;
    height: 5vh;
  } */
}

@media only screen and (min-width: 1200px) {
  .skill {
    min-height: 30vh;
    height: 30vh;
    min-width: 20vw;
  }
  #overview #grad {
    width: 40%;
  }
  #projects p {
    margin: auto;
    color: #5d5c61;
    text-align: center;
    font-family: "Raleway", sans-serif;
  }
  .project {
    background: #ffffff;
    box-shadow: 1px 4px 10px 1px rgba(144, 144, 144, 0.27);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 20px;
    width: 25%;
    height: 30%;
    max-height: 30%;
    min-height: 30%;
  }

  .project h4 {
    font-family: "Montserrat", sans-serif;
    color: #292929;
  }

  .buttonContainer {
    padding-top: 5%;
  }
  .readBtn,
  .viewBtn {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin: 5px;
  }

  .story {
    width: 100%;
  }
  .gifs div {
    width: 30%;
    text-align: center;
    margin: 1%;
  }

  .about #gradIcon {
    width: 60%;
  }
  .designImg {
    width: 30%;
    margin-bottom: 5px;
    margin-right: 2px;
  }
  #overview .moreAbout {
    align-items: baseline;
  }
  #overview p {
    text-align: left;
  }

  .moreAbout h6 {
    text-align: center;
  }
  .moreAbout div {
    align-items: baseline;
  }
}

/************************************ END SCREEN QUERIES ************************************/
